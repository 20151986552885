import { User } from '@prisma/client';
import { Provider } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { ReactNode } from 'react';
import { userAtom } from './atoms';

export type HydrationData = {
  user: User | null;
};

export default function GlobalJotaiProvider({
  children,
  ...hydrationData
}: HydrationData & {
  children: ReactNode;
}) {
  return (
    <Provider>
      <HydrateGlobalJotaiAtoms {...hydrationData} />
      {children}
    </Provider>
  );
}

function HydrateGlobalJotaiAtoms(props: HydrationData) {
  useHydrateAtoms([[userAtom, props.user]]);
  return null;
}

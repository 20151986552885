function getEnvVariable(name: string): string {
  const value = process.env[name];
  if (!value) {
    throw new Error(`'${name}' environment variable not set.`);
  }
  return value;
}

const env = {
  appName: 'opened',

  environment: process.env.NEXT_PUBLIC_APP_ENV,

  get baseUrl() {
    if (env.isProduction) {
      return 'https://opened.co';
    } else if (env.isStaging) {
      return 'https://opened.dev';
    }
    return 'http://localhost:3000';
  },

  get isDevelopment() {
    return env.environment === 'development';
  },
  get isTest() {
    return env.environment === 'test';
  },
  get isStaging() {
    return env.environment === 'staging';
  },
  get isProduction() {
    return env.environment === 'production';
  },

  get isBrowser() {
    return typeof window !== 'undefined';
  },
  get isLocal() {
    return env.isDevelopment || env.isTest;
  },

  get googleGroupEmailSupport() {
    return getEnvVariable('GOOGLE_GROUP_EMAIL_SUPPORT');
  },
  get googleGroupEmailTeacher() {
    return getEnvVariable('GOOGLE_GROUP_EMAIL_TEACHER');
  },
  get companyEmailDomain() {
    return getEnvVariable('COMPANY_EMAIL_DOMAIN');
  },
  get googleWorkspaceAdminEmail() {
    return getEnvVariable('GOOGLE_WORKSPACE_ADMIN_EMAIL');
  },
  get googleProjectId() {
    return getEnvVariable('GOOGLE_PROJECT_ID');
  },
  get googlePrivateKeyId() {
    return getEnvVariable('GOOGLE_PRIVATE_KEY_ID');
  },
  get googlePrivateKey() {
    return getEnvVariable('GOOGLE_PRIVATE_KEY');
  },
  get googleClientEmail() {
    return getEnvVariable('GOOGLE_CLIENT_EMAIL');
  },
  get googleClientId() {
    return getEnvVariable('GOOGLE_CLIENT_ID');
  },
  get mockServerUrl() {
    return getEnvVariable('MOCK_SERVER_URL');
  },
};

export default env;

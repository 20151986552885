'use client';
import { GlobalProgressBar } from '@/components/ui/global-progress';
import { Toaster } from '@/components/ui/toaster';
import config from '@/config';
import { SessionProvider } from 'next-auth/react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { type ReactNode } from 'react';
import GlobalJotaiProvider, { HydrationData } from './jotai';

if (typeof window !== 'undefined' && config.analytics.posthog.enabled) {
  posthog.init(config.analytics.posthog.key, {
    api_host: config.analytics.posthog.host,
  });
}

export default function Providers({
  children,
  ...hydrationData
}: HydrationData & { children: ReactNode }) {
  return (
    <>
      <PostHogProvider client={posthog}>
        <GlobalJotaiProvider {...hydrationData}>
          <GlobalProgressBar>
            <SessionProvider>{children}</SessionProvider>
            <Toaster />
          </GlobalProgressBar>
        </GlobalJotaiProvider>
      </PostHogProvider>
    </>
  );
}
